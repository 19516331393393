import classnames from 'classnames';
import React, { useState } from 'react';
import { Footer } from '../components/footer';

const Impressum = () => {
  const [maskTopActive, setMaskTopActive] = useState(false);
  return (
    <div className="page style-2">
      <div className="content">
        <a href="/">zurück zur Startseite</a>
        <h2>Impressum</h2>
        <h3>Verantwortlich für den Inhalt</h3>
        <p>
          Philip Mancarella (
          <a href="mailto:info@philipmancarella.de">info@philipmancarella.de</a>
          )
        </p>
        <h3>Anschrift</h3>
        <p>
          Philip Mancarella
          <br />
          Benfleetstr. 45
          <br />
          50858 Köln
        </p>
        <h2>Haftungsausschluss</h2>
        <h3>Haftung für Inhalte</h3>
        <p>
          Die Inhalte dieser Website wurden mit größter Sorgfalt erstellt. Für
          die Richtigkeit, Vollständigkeit und Aktualität der Inhalte kann ich
          jedoch keine Gewähr übernehmen. Als Diensteanbieter bin ich gemäß § 7
          Abs.1 TMG für eigene Inhalte auf dieser Website nach den allgemeinen
          Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG bin ich als
          Diensteanbieter jedoch nicht verpflichtet, übermittelte oder
          gespeicherte fremde Informationen zu überwachen oder nach Umständen zu
          forschen, die auf eine rechtswidrige Tätigkeit hinweisen.
          Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
          Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt.
          Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der
          Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden
          von entsprechenden Rechtsverletzungen werde ich diese Inhalte umgehend
          entfernen.
        </p>
        <h3>Haftung für Links</h3>
        <p>
          Meine Website enthält ggf. Links zu externen Webseiten Dritter, auf
          deren Inhalte ich keinen Einfluss habe. Deshalb kann ich für diese
          fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der
          verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der
          Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der
          Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige
          Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine
          permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne
          konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei
          Bekanntwerden von Rechtsverletzungen werde ich derartige Links
          umgehend entfernen.
        </p>
        <h3>Urheberrecht</h3>
        <p>
          Die durch den Seitenbetreiber erstellten Inhalte und Werke auf dieser
          Website unterliegen dem deutschen Urheberrecht. Die Vervielfältigung,
          Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der
          Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des
          jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite
          sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.
          Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt
          wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden
          Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf
          eine Urheberrechtsverletzung aufmerksam werden, bitte ich um einen
          entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werde
          ich derartige Inhalte umgehend entfernen.
        </p>
      </div>
      <Footer />
      <div className={classnames('mask top', { active: maskTopActive })}></div>
      <div className="mask bottom active"></div>
    </div>
  );
};

export default Impressum;
