import React, { ComponentType } from 'react';
interface Props {}
export const Footer: ComponentType<Props> = ({}) => {
  return (
    <div id="footer">
      <ul>
        <li>
          <a href="/impressum">Impresssum</a>
        </li>
        <li>
          <a href="/datenschutz">Datenschutz</a>
        </li>
      </ul>
      <div className="copyright">
        &copy; 2021 - Philip Mancarella / I Love Computers &amp; Internet
      </div>
    </div>
  );
};
